@import "./styles/Components.css";
@import "./styles/Header.css";
@import "./styles/AddIngredients.css";

.App {
  background-color: #faf6f0;
  height: 100%;
  width: 100%;
}

.mainContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
