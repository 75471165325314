.Combinations__Wrapper {
  background-color: #e9e8e8;
  width: 217px;
  height: 410px;
  overflow-y: auto;
}

.Recipe_Name {
  padding: 0.1rem;
}

.sadFridgeImg {
  height: 410px;
}

.recipeContainer {
  width: 438px;
  height: 370px;
  padding: 20px;

  background-color: #e9e8e8;
}

.footer {
  height: auto;
  margin-top: 50px;
  background-color: #e9e8e8;
  padding: 20px;
}

.us {
  margin: 3px;
}

.aboutUs {
  padding-bottom: 20px;
  border-bottom: 2px solid #ddd;
}
