.Header_Sad_Fridge {
  height: 100px;
  padding: 10px;
}

.Title_Sad_Fridge {
  position: absolute;
  top: 20px;
  left: 50%;
  background-color: white;
}

.h {
  height: 2px;
  border: 0 none;
  background-color: #dddddd;
}

.h2 {
  position: relative;
  top: 12px;
  width: 80%;
  height: 2px;
  border: 0 none;
  background-color: #dddddd;
}

.logo {
  position: relative;
  width: 4.5em;
  height: 0;
}
.logo .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
}
.logo img {
  width: 100%;
  padding: 0.5em;
}
p {
  font-size: 1.6rem;
}

.navbar {
  height: 60px;
  display: flex;
  top: 0;
  position: static;
  background-color: #ddd;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: #245c7c;
  font-weight: bold;
}

a:hover {
  color: gray;
}
